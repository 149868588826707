import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import PrismicVue from '@prismicio/vue'

const endpoint = "https://jankristofercruz-dot-com.prismic.io/api/v2"

Vue.config.productionTip = false

Vue.use(PrismicVue, {
  endpoint
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
